import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby';
import Img from 'gatsby-image'
import {
  CatalogHeader,
  CatalogFooter,
  CatalogItem,
  CatalogItemDescription
} from '../components'
import {
  ProductWrapper,
  ProductInner,
  ProductInfos,
  ImageContainer,
  CatalogWrapper
} from '../templates/catalogItemStyle'

import isMobile from 'is-mobile'

class Catalog extends React.Component {

    state = {
      loaded: false
    }

    componentDidMount() {
      const {
        pageContext: { 
          redirect
        }
      } = this.props

      if (isMobile()) {
        navigate(redirect);
      } else {
        this.setState({ loaded: true })
      }
    }

    render() {
      
      const context = { 
        slug: '/catalog', 
        title: this.props.data.catalog.data.title.text,
        parent: '' 
      };

      const catalogPages = this.props.data.catalog.data.body;

      return (
        <React.Fragment>
          <Helmet title={`Disch Rémy - Catalog`}>
            <html lang={`en`} />
            <link rel="stylesheet" type="text/css" href="https://unpkg.com/xzoom/dist/xzoom.css" media="all" />
          </Helmet>
          <CatalogHeader {...context} />
          {
            this.state.loaded && (
              <React.Fragment>
                {
                  catalogPages.map((item, index) => {
                    return item.items.length > 0 ? (
                      <React.Fragment key={`page-pics-${index}`}>
                        {item.items.length > 1 ? (
                          <React.Fragment>
                            <CatalogWrapper>
                              {
                                item.items.map((i, n) => {
                                  return (
                                    <React.Fragment key={`page-artworks-${index}-${n}`}>
                                      <CatalogItem 
                                        key={`page-artworks-${index}-${n}`} 
                                        currentArtwork={i.artwork}
                                        count={item.items.length}
                                        elementId={`item-${index}-${n}`}
                                        id={n}
                                      />
                                    </React.Fragment>
                                  )
                                })
                              }
                            </CatalogWrapper>
                            <CatalogWrapper className="description">
                              {
                                item.items.map((i, n) => {
                                  return (
                                    <CatalogItemDescription 
                                      key={`page-artworks-descriptions-${index}-${n}`} 
                                      currentArtwork={i.artwork}
                                      count={item.items.length}
                                      elementId={`item-${index}-${n}`}
                                      id={n}
                                    />
                                  )
                                })
                              }
                            </CatalogWrapper>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <CatalogWrapper 
                              className={item.items[0].artwork.document[0].data.catalog_fullscreen ? 'fullscreen' : 'default'}
                            >
                              {
                                item.items.map((i, n) => {
                                  return (
                                    <CatalogItem 
                                      key={`page-artwork-${n}`} 
                                      currentArtwork={i.artwork}
                                      count={item.items.length}
                                      elementId={`item-${index}-${n}`}
                                      id={n}
                                      zoomPosition={'left'}
                                    />
                                  )
                                })
                              }
                            </CatalogWrapper>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`page-pic-${index}`}>
                        {
                          (item.primary.image.localFile && item.primary.text.html) ? (
                            <ProductWrapper className="content">
                              <ProductInner className="content">
                                {
                                  item.primary.image_left ? (
                                    <React.Fragment>
                                      <ImageContainer className="content">
                                        <Img
                                          alt={`page-pic-${index}`}
                                          fluid={item.primary.image.localFile.childImageSharp.fluid}
                                          placeholderStyle={{
                                            filter: "grayscale(100%) contrast(100%)",
                                            imageRendering: "pixelated",
                                            opacity: 0,
                                          }}
                                        />
                                      </ImageContainer>
                                      <ProductInfos>
                                        <div 
                                          className={`descriptions ${item.primary.text_rtl ? 'ltr' : ''}`} 
                                          dangerouslySetInnerHTML={{ __html: item.primary.text.html }}
                                        ></div>
                                      </ProductInfos>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <ProductInfos>
                                        <div 
                                          className={`descriptions ${item.primary.text_rtl ? 'ltr' : ''}`}
                                          dangerouslySetInnerHTML={{ __html: item.primary.text.html }}
                                        ></div>
                                      </ProductInfos>
                                      <ImageContainer className="content">
                                        <Img
                                          alt={`page-pic-${index}`}
                                          fluid={item.primary.image.localFile.childImageSharp.fluid}
                                          placeholderStyle={{
                                            filter: "grayscale(100%) contrast(100%)",
                                            imageRendering: "pixelated",
                                            opacity: 0,
                                          }}
                                        />
                                      </ImageContainer>
                                    </React.Fragment>
                                  )
                                }
                              </ProductInner>
                            </ProductWrapper>
                          ) : (
                            <ProductWrapper className={`${item.primary.banner ? 'banner picture' : 'portrait picture'}`}>
                              <Img
                                alt={`page-pic-${index}`}
                                fluid={item.primary.image.localFile.childImageSharp.fluid}
                                placeholderStyle={{
                                  filter: "grayscale(100%) contrast(100%)",
                                  imageRendering: "pixelated",
                                  opacity: 0,
                                }}
                              />
                            </ProductWrapper>
                          )
                        }
                      </React.Fragment>
                    )
                  })
                }
              </React.Fragment>
            )
          }
          <CatalogFooter data={this.props.data.catalog} />
        </React.Fragment>
      )
    }
}

export default Catalog;
export const pageQuery = graphql`
  query CatalogQuery($slug: String!) {
    catalog: prismicCatalog(uid: {eq: $slug}) {
      uid
      data {
        title {
          text
        }
        body {
          items {
            artwork {
              document {
                prismicId
                uid
                data {
                  meta_description
                  meta_title
                  title {
                    text
                  }
                  drouot_estimate
                  catalog_fullscreen
                  type
                  available
                  on_demand
                  price
                  item {
                    alt
                    localFile {
                      childImageSharp {
                        fixed(height: 800, quality: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                        fluid(maxWidth: 800, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  cadre {
                    alt
                    localFile {
                      childImageSharp {
                        fixed(height: 800, quality: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                        fluid(maxWidth: 800, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  image_hd {
                    localFile {
                      childImageSharp {
                        original {
                          height
                          src
                          width
                        }
                      }
                    }
                  }
                  categories {
                    ... on PrismicArtworkDataCategories {
                      category {
                        uid,
                        document {
                          data {
                            name
                          }
                        }
                      }
                    }
                  }
                  year {
                    ... on PrismicArtworkDataYear {
                      uid
                    }
                  }
                }
              }
            }
          }
          primary {
            image {
              localFile {
                childImageSharp {
                  fixed(height: 800, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            text {
              html
            }
            banner
            text_rtl
            image_left
          }
        }
      } 
    }
  }
`